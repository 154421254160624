import React from 'react'

const Loader = () => {
  return (
    <div>
        {/* // 	//<!-- Preloader -->  */}
        <div id="preloader">
        <div class="loader">
        <div class="load-circle"><div></div><div></div></div>
     </div>
            </div> 
 {/* <!-- Preloader end-->  */}
      
    </div>
  )
}

export default Loader
